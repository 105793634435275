import React, { useState } from 'react'
import './chatbot.css'
import axios from 'axios'
import config from '../../config';


const Chatbot = () => {
  const [isChatOpen, setIsChatOpen] = useState(false)
  const [messages, setMessages] = useState([{ type: 'operator', content: 'Hello Doctor, How can I assist you?' }])
  const [showTerms, setShowTerms] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [isChecked, setIsChecked] = useState(false)
  const [loading, setLoading] = useState(false)

  const toggleChat = () => {
	const profileData = JSON.parse(localStorage.getItem('profileData'))
	var terms = profileData.terms;
	
    if (terms !== 'Y') {
      setShowTerms(true)
    }else{
		setIsChatOpen(!isChatOpen)
	}
    if (isChatOpen) {
      // If chat is being closed, clear the messages
      setMessages([{ type: 'operator', content: 'Hi! How can I help you today?' }])
    }

  }

  const handleInputChange = (e) => setInputValue(e.target.value)

  const handleSend = () => {
	   const trimmedInput = inputValue.trim();
    if (trimmedInput) {
		
		const newMessages = [...messages, { type: 'user', content: trimmedInput }]
		setMessages(newMessages)
		setLoading(true)	  
		const key = (localStorage.getItem("key"));
		let formData = new FormData();
		formData.append("key", key);
		formData.append("query", trimmedInput);
				 
		axios.post(`${config.BASE_API_URI}/CalltoBot.php`,formData ).then(res => {
			
			console.log(res.data[1])
			 
			  
			var res_query = JSON.parse(res.data[1]);
			  
			const text = renderMessageContent(res_query.response);
			
			setMessages((prevMessages) => [...prevMessages, { type: 'operator', content: text }])

			setLoading(false)
			
		})

      // Clear input
      setInputValue('')
    }
  }
  
  const renderMessageContent = (content) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g
    const parts = content.split(urlRegex)

    return parts.map((part, index) => {
      if (urlRegex.test(part)) {
        // Render as a clickable link
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'blue', textDecoration: 'none' }}
          >
            {part}
          </a>
        )
      }
      // Render as plain text
      return part
    })
  }

  const handleAgree = async () => {
		const key = (localStorage.getItem("key"));
		let formData = new FormData();
		formData.append("key", key);
		formData.append("terms", 'Y');
	  
	  axios.post(`${config.BASE_API_URI}/AgreedTerms.php`,formData ).then(res => {
			
			
			 
			  var response = (res.data[1]);
			  if(response.Message === 'success'){
				  let profileData = JSON.parse(localStorage.getItem("profileData"));
				  const updatedProfileData = {
				  ...profileData,
				  terms: 'Y' // Replace 'new focus' with the desired new value
				};
				
				// Update state and localStorage

				localStorage.setItem('profileData', JSON.stringify(updatedProfileData))
				
				setShowTerms(false)
				setIsChatOpen(true)
			  }
		})
    
	
   
  }

  return (
    <div className="chat-board chatbot">
      <div className={`frame-content ${isChatOpen ? 'open' : 'closed'}`}>
        {isChatOpen && (
          <div className="chat">
            <div
              className="chat-header"
              style={{
                background: 'linear-gradient(135deg, rgb(42, 39, 218) 0%, rgb(0, 204, 255) 100%)',
              }}
            >
              <h2>RPG Bot: AI Chat</h2>
              <button onClick={toggleChat} className="minimize-button" aria-label="Minimize">
                <i class="fas fa-times-circle"></i>
              </button>
            </div>
            <div id="conversation-group" role="log">
              <div id="messages">
                {messages.map((msg, index) => (
                  <div key={index} className={`message message-${msg.type}`}>
                    <span className="message-content">{msg.content}</span>
                  </div>
                ))}
				 {loading && (
                  <div className="message message-operator">
                    <span className="message-content">...</span>
                  </div>
                )}
              </div>
            </div>
            <div className="input-group">
              <hr />
			  <div class='text-end'>
			  	<a rel="noreferrer noopener" href={`${config.WEBSITE_LINK}/website/terms_and_conditions.php`} className='ml-1'>terms</a>
				<a rel="noreferrer noopener" href={`${config.WEBSITE_LINK}/website/disclaimer.php`} className='ml-1'>disclaimer</a>
			  </div>
              <div className="footer-input-wrapper">
                <textarea
                  id="chat-input"
                  rows="1"
                  placeholder="Type your message..."
                  value={inputValue}
                  onChange={handleInputChange}
                 
                ></textarea>
                <button onClick={handleSend} className="send-icon" title="Send Message">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" xmlSpace="preserve">
                    <path
                      fill="#d7d7d7"
                      d="M22,11.7V12h-0.1c-0.1,1-17.7,9.5-18.8,9.1c-1.1-0.4,2.4-6.7,3-7.5C6.8,12.9,17.1,12,17.1,12H17c0,0,0-0.2,0-0.2c0,0,0,0,0,0c0-0.4-10.2-1-10.8-1.7c-0.6-0.7-4-7.1-3-7.5C4.3,2.1,22,10.5,22,11.7z"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        )}
        <div id="chat-button" onClick={toggleChat}>
          <div className="buttonWave"></div>
          <button
            id="button-body"
            className="chat-toggle-button"
            
          >
            <img src={`${config.CUSTOM_IMAGES_URL}/ServBot.png`} alt="Icon" style={{ width: '75px', height: '75px' }} />
          </button>
        </div>
      </div>
	  
      <div
        className={`modal fade ${showTerms ? 'show d-block' : ''}`}
        tabIndex="-1"
        role="dialog"
        style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Disclaimer</h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => setShowTerms(false)}
              ></button>
            </div>
            <div className="modal-body">
              <h5>Disclaimer</h5>
              <p>By using RPG Bot or RPGserv App/Services , you agree to our Terms and Conditions:</p>
              <ul>
			    <li>Before using RPG Bot or any services, please click on the links provided to read detailed disclaimer and usage terms.</li>
              </ul>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="agreeCheckbox"
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                />
                <label className="form-check-label" htmlFor="agreeCheckbox">
                  I have read and agree to the Terms and Conditions
                </label>
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleAgree}
                disabled={!isChecked} // Disable until the checkbox is checked
              >
                I agree
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Chatbot
